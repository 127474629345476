<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <b-table
        class="position-relative no-headers"
        responsive
        show-empty
        align-v="end"
        :items="[
          { user: '', action: '' },
          { user: '', action: '' }
        ]"
        :fields="tableColumns"
        :empty-text="$t('NoMatchingRecordsFound')"
      >
        <!-- Column: User -->
        <template #cell(user)="data">
          <b-media class="align-item-center">
            <template #aside>
              <b-avatar size="50" :src="RA" text="R" variant="light-primary" />
            </template>
            <h6 class="mb-0">Peter Reed</h6>
            <small class="text-muted">{{ $t("Supervisor") }}</small>
          </b-media>
        </template>
        <template #cell(action)="data">
          <div class="text-right">
            <b-badge class="badge-cust"> {{ $t("Warning") }} </b-badge>
            <b-button
              variant="primary"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-modal.modal-sm-dealer-invitations
            >
              <span class="text-nowrap">{{ $t("user.Resend") }}</span>
            </b-button>
          </div>
        </template>
      </b-table>
      <div class="mx-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            &nbsp;
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <DealerInvitationsModal />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BModal,
  BForm
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import DealerInvitationsModal from "@/layouts/components/ConsumerOnboarding/DealerInvitationsModal.vue";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BFormGroup,
    BModal,
    BForm,
    DealerInvitationsModal
  },
  data() {
    return {
      tableColumns: [
        { key: "user", label: this.$t("historyTableColumns.user") },
        { key: "action", label: this.$t("historyTableColumns.action") }
      ],
      selected: "USA",
      option: ["USA", "Canada", "Maxico"]
    };
  }
};
</script>

<style lang="scss" scoped></style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
