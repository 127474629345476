<template>
  <div>
    <!-- Table Container Card -->
    <div class="adduser-btn-row w-360 del">
      <h4 class="card-title">
        {{ !isUserShow ? $t("SubAccounts.Dealerlist") : userAccountName }}
      </h4>
      <b-button
        variant="primary"
        class="btn-icon"
        v-if="!isUserDealer"
        @click="ShowSentInvitation"
      >
        <feather-icon icon="UserPlusIcon" />
      </b-button>
      <div class="d-flex w-100 justify-content-end">
        <search
          style="float: left"
          class="search-comp btn-icon no-btm-0"
          v-model="searchFiled"
          :searchColumn="searchColumn"
          :operator="operator"
        ></search>
        <span>
          <b-button
            variant="primary"
            v-b-tooltip.hover.left="$t('tooTip.addAccount')"
            class="btn-icon ml-1"
            v-if="
              !isUserShow && $route.params.account_id
                ? checkAbility({
                    action: constants.PERMISSIONS_ACTION.ACCOUNT_ADD,
                    subject: constants.PERMISSIONS_MODEL.ACCOUNT
                  })
                : checkAbility({
                    action: constants.PERMISSIONS_ACTION.ADD,
                    subject: constants.PERMISSIONS_MODEL.ACCOUNT
                  })
            "
            @click="rediectOnDealerScreen()"
          >
            <feather-icon icon="UserPlusIcon" />
            <span class="d-none d-sm-inline ml-1"
              >{{ $t("ManageAccount.Add") }}
              {{ $t("Breadcrumb_.Dealer") }}</span
            >
          </b-button>
        </span>
        <b-button
          variant="outline-primary"
          size="sm"
          class="add-user-btn v2-back ml-10"
          @click="$router.go(-1)"
        >
          <feather-icon icon="ArrowLeftIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">{{ $t("role.Back") }}</span>
        </b-button>
      </div>
    </div>
    <b-card no-body class="mb-0" v-if="!isUserShow">
      <div class="table-responsive" v-if="show">
        <b-skeleton-table
          v-if="show"
          :rows="10"
          :columns="1"
          :table-props="{ bordered: true, striped: true }"
        />
      </div>
      <b-table
        v-if="!show"
        class="position-relative table-responsive no-headers"
        :class="
          $route.name === 'dealer' ? 'dealer-min-650-m' : 'dealer-min-650'
        "
        responsive
        show-empty
        align-v="end"
        :items="items"
        :fields="tableColumns"
        :empty-text="$t('NoMatchingRecordsFound')"
      >
        <template #cell(user)="row">
          <b-media class="align-item-center">
            <template #aside>
              <b-avatar
                size="50"
                src="RA"
                :text="row.item.avtar_txt"
                :variant="row.item.variant"
              />
            </template>
            <h6 class="mb-0">
              {{ row.item.name }}
            </h6>
            <small class="text-muted">{{
              row.item.legal_entity
                ? $t("profile.Business")
                : $t("profile.Personal")
            }}</small>
          </b-media>
        </template>
        <template #cell(action)="row">
          <div class="text-right">
            <feather-icon
              icon="UsersIcon"
              size="18"
              variant="primary"
              class="cursor-pointer"
              v-b-tooltip.hover.top="$t('user.ManageSubAccounts')"
              style="position: relative; margin-right: 15px"
              @click="subDealerShow(row.item)"
              v-if="
                row.item.status === 'ACTIVE' &&
                !row.item.restrict_sub_accounts &&
                checkAbility({
                  action: constants.PERMISSIONS_ACTION.ACCOUNT_READ,
                  subject: constants.PERMISSIONS_MODEL.ACCOUNT
                })
              "
            />

            <feather-icon
              icon="UserIcon"
              size="18"
              variant="primary"
              v-b-tooltip.hover.top="$t('user.ManageUsers')"
              class="cursor-pointer"
              style="position: relative; margin-right: 10px"
              @click="userShow(row.item)"
              v-if="
                row.item.status === 'ACTIVE' &&
                checkAbility({
                  action: constants.PERMISSIONS_ACTION.READ,
                  subject: constants.PERMISSIONS_MODEL.USER_ACCOUNT
                })
              "
            />

            <b-link
              :to="{
                name: $route.params.account_id
                  ? 'sub-account-update-details'
                  : 'account-update-details',
                query: {
                  id: row.item.id,
                  isEdit: true,
                  account_id: $route.params.account_id
                }
              }"
              v-if="
                $route.params.account_id
                  ? checkAbility({
                      action: constants.PERMISSIONS_ACTION.ACCOUNT_UPDATE,
                      subject: constants.PERMISSIONS_MODEL.ACCOUNT
                    })
                  : checkAbility({
                      action: constants.PERMISSIONS_ACTION.UPDATE,
                      subject: constants.PERMISSIONS_MODEL.ACCOUNT
                    })
              "
            >
              <feather-icon
                v-b-tooltip.hover.top="$t('tooTip.update')"
                icon="EditIcon"
                class="text-primary action-icon cursor-pointe"
                size="18"
              />
            </b-link>

            <b-dropdown
              :text="
                row.item.status
                  ? $t(`device.StatusList.${row.item.status}`)
                  : $t('device.StatusList.ACTIVE')
              "
              id="dropdown-dropright"
              v-if="
                $route.params.account_id
                  ? checkAbility({
                      action: constants.PERMISSIONS_ACTION.ACCOUNT_UPDATE,
                      subject: constants.PERMISSIONS_MODEL.ACCOUNT
                    })
                  : checkAbility({
                      action: constants.PERMISSIONS_ACTION.UPDATE,
                      subject: constants.PERMISSIONS_MODEL.ACCOUNT
                    })
              "
              v-model="row.item.status"
              :variant="
                row.item.status && row.item.status == 'INACTIVE'
                  ? 'danger'
                  : row.item.status && row.item.status == 'BLOCKED'
                  ? 'warning'
                  : 'primary'
              "
              class="m-l-0 m-2 btn-min-width"
              size="sm"
            >
              <b-dropdown-item
                @click="updateStatus(row.item, 'ACTIVE')"
                class="btn-min-width"
                >{{ $t("active") }}</b-dropdown-item
              >
              <b-dropdown-item
                @click="updateStatus(row.item, 'INACTIVE')"
                class="btn-min-width"
                >{{ $t("inactive") }}</b-dropdown-item
              >
              <b-dropdown-item
                @click="updateStatus(row.item, 'BLOCKED')"
                class="btn-min-width"
                >{{ $t("blocked") }}</b-dropdown-item
              >
            </b-dropdown>
          </div>
        </template>
      </b-table>
      <div class="mx-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            &nbsp;
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-if="totalDealer > 0"
              v-model="currentPage"
              :total-rows="totalDealer"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              :no-paging-nav="false"
              :active-class="activeClass"
              @input="handlePageChange"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <!-- <Loader :show="show" /> -->
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  VBTooltip,
  BTooltip,
  BSkeletonTable
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import AreYouSureModal from "@/layouts/components/AreYouSureModal.vue";
import AccountService from "@/libs/api/account-service";
import Ripple from "vue-ripple-directive";
import Loader from "@/layouts/components/Loader.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Search from "@/views/Common/search.vue";
import constants from "@/utils/constants";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    Loader,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    AreYouSureModal,
    VBTooltip,
    BTooltip,
    BSkeletonTable,
    Search
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple
  },
  data() {
    return {
      tableColumns: [
        { key: "user", label: this.$t("historyTableColumns.user") },
        { key: "action", label: this.$t("historyTableColumns.action") }
      ],
      totalDealer: 0,
      user: {},
      perPage: this.$route.query.perPage ? this.$route.query.perPage : 10,
      currentPage: this.$route.query.currentPage
        ? this.$route.query.currentPage
        : 1,
      activeClass: "active",
      items: [],
      show: false,
      isUserShow: false,
      accountId: "",
      isUserDealer: true,
      userAccountName: "",
      searchColumn: ["name"],
      searchFiled: {},
      filter: {},
      operator: "ilike"
    };
  },
  props: ["userListShow", "selectedAccount"],
  mounted() {
    this.$nextTick(() => {
      const { page, filter, perPage } = this.$route.query;

      if (!page) {
        this.updateUrl({ key: "page", value: 1 });
      } else {
        this.currentPage = parseInt(page);
      }

      if (filter) {
        this.filter = filter;
      }

      if (perPage) {
        this.perPage = parseInt(perPage);
      }

      if (
        localStorage.getItem("USER_ACCOUNT_TYPE") === "CONSUMER" &&
        this.$route.name === "dealer"
      ) {
        this.$router.push({ name: "unauthorized" });
      }

      this.debounceGetDealerList();
    });
  },
  watch: {
    $route(to, from) {
      if (to.query && from.query && to.query.filter != from.query.filter) {
      } else {
        this.debounceGetDealerList();
      }
    },
    perPage(val) {
      if (!this.perPage) {
        this.perPage = 10;
      }
      this.updateUrl({ key: "perPage", value: this.perPage });
      this.debounceGetDealerList();
    },

    currentPage(newPage) {
      this.debounceGetDealerList();
    },

    searchFiled(val) {
      this.filter = val;
      this.debounceGetDealerList();
    }
  },
  methods: {
    debounceGetDealerList() {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        this.getDealer();
      }, 300); // Adjust the delay as necessary
    },
    updateUrl(e) {
      this.$router.push({
        query: { ...this.$route.query, [e.key]: e.value }
      });
    },
    SHOWuSER() {
      this.$bvModal.hide("modal-sm-remove");
    },
    callGetDealer() {
      if (this.$route && this.$route.query && this.$route.query.tab) {
        if (this.$route.query.tab === "dealer") {
          this.getDealer();
        }
      } else {
        this.getDealer();
      }
    },

    ShowSentInvitation() {
      this.$bvModal.show("modal-sm-invitations");
    },
    getAccountsDetails(account) {},
    async getDealer() {
      try {
        this.show = true;
        if (!this.filter || !this.filter.value) {
          if (this.$route.query.filter) {
            this.filter = {
              field: "all",
              operator: "ilike",
              value: this.$route.query.filter
            };
          } else {
            this.filter = [];
          }
        }
        let accountIdObj = this.$route.params.account_id
          ? { account_id: this.$route.params.account_id }
          : {};
        store.commit("mapLoader/SET_IS_BREADCRUMB_LOADING", true);
        let response = this.$route.params.account_id
          ? await new AccountService().getSubDealerList({
              page: this.currentPage,
              type: "DEALER",
              page_size: parseInt(this.perPage),
              ...accountIdObj,
              filters:
                this.filter && Object.keys(this.filter).length > 0
                  ? [this.filter]
                  : []
            })
          : await new AccountService().getDealerList({
              page: this.currentPage,
              type: "DEALER",
              page_size: parseInt(this.perPage),
              filters:
                this.filter && Object.keys(this.filter).length > 0
                  ? [this.filter]
                  : []
            });

        if (response && response.data) {
          store.commit("mapLoader/SET_IS_BREADCRUMB_LOADING", false);
          this.show = false;
          this.items = response.data.list || [];
          this.items = this.items.map((u) => {
            u.variant = this.getRandomBgColor();
            u.avtar_txt = this.userAvatarTxt(u.name);
            u.manage_user = `manage_user${u.id}`;
            u.manage_account = `manage_account${u.id}`;
            return u;
          });
          if (
            response.data.newAncestor &&
            response.data.newAncestor.length &&
            this.$route.meta.breadcrumb &&
            this.$route.meta.breadcrumb.length
          ) {
            this.$route.meta.breadcrumb = JSON.parse(
              JSON.stringify(constants.DEALER_ROUTE_META_BREADCRUMB)
            );

            response.data.newAncestor.forEach((a, i) => {
              let active = false;
              this.$route.meta.breadcrumb[0].active = false;
              if (this.$route.params.account_id == a.id) {
                active = true;
              }
              this.$route.meta.breadcrumb.push({
                text: a.name,
                to: {
                  params: { account_id: a.id },
                  name: "sub_dealer"
                },
                active,
                index: a.name + i
              });
            });
          }
          this.totalDealer = response.data.pagination.total_records || 0;
        } else if (response && response.error && response.error.message) {
          store.commit("mapLoader/SET_IS_BREADCRUMB_LOADING", false);
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        store.commit("mapLoader/SET_IS_BREADCRUMB_LOADING", false);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.show = false;
      }
    },
    rediectOnDealerScreen() {
      const query = this.$route.params.account_id
        ? {
            query: { id: this.$route.params.account_id, tab: this.$route.name }
          }
        : "";
      this.$router.push({ name: "dealer-creation", ...query });
    },
    close(userData) {
      this.user = userData;
      this.$bvModal.show("modal-sm-remove");
    },
    onClose() {
      this.$bvModal.hide("modal-sm-remove");
    },
    handlePageChange(newPage) {
      this.updateQueryParam();
    },
    updateQueryParam() {
      const query = {
        ...this.$route.query,
        page: this.currentPage.toString()
      };
      if (this.filter && this.filter.value) {
        query.filter = this.filter.value;
      }
      this.$router.replace({ query }).catch(() => {});
      // this.callGetDealer();
    },
    userShow(e) {
      if (this.$route.meta.breadcrumb && this.$route.meta.breadcrumb.length) {
        if (this.$route.name === "sub_dealer") {
          this.$route.meta.breadcrumb.forEach((e) => {
            e.active = false;
          });
          this.$route.meta.breadcrumb.push({
            text: e.name,
            to: { params: { account_id: e.id }, name: "sub_dealer" }
          });
        } else if (this.$route.name === "dealer") {
          this.$route.meta.breadcrumb.forEach((e) => {
            e.active = false;
          });
          this.$route.meta.breadcrumb.push({
            text: e.name,
            to: { name: "dealer", query: { tab: "dealer" } }
          });
        }
      }
      this.userListShow(e);
      this.isUserShow = true;
      this.isUserDealer = false;
      this.accountId = e;
      this.userAccountName = e.name;
    },
    dealerShow(e) {
      this.isUserDealer = true;
      this.isUserShow = false;
    },
    subDealerShow(e) {
      this.$router.push({ params: { account_id: e.id }, name: "sub_dealer" });
    },
    async updateStatus(account, status) {
      try {
        this.show = true;
        let response = this.$route.params.account_id
          ? await new AccountService().updateDealerSubAccountStatus({
              account_id: account.id,
              status: status,
              type: "DEALER"
            })
          : await new AccountService().updateDealerAccountStatus({
              account_id: account.id,
              status: status,
              type: "DEALER"
            });
        if (response && response.data) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("SubAccounts.AccountStatustitle"),
              text: this.$t("SubAccounts.AccountStatusText"),
              icon: "EditIcon",
              variant: "success"
            }
          });
          this.getDealer();
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.show = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.table-responsive {
  height: calc(100vh - 300px);
}
</style>
<style lang="scss">
.search-card {
  .card-body {
    min-height: 0 !important;
  }
}
.dealer-min-650 {
  // min-width: 650px;
  // min-height: 40vh;
  overflow-y: auto;
  height: calc(100vh - 300px) !important;
  .b-table {
    width: calc(100% - 18px) !important;
  }
}
.dealer-min-650 .table {
  min-width: 650px;
}
.dealer-min-650-m {
  overflow-y: auto;
  height: calc(100vh - 270px) !important;
  .b-table {
    width: calc(100% - 18px) !important;
  }
}
@media screen and (max-width: 667px) {
  .del {
    .d-flex.w-100.justify-content-end {
      justify-content: flex-start !important;
    }
    .nav.nav-pills {
      margin-bottom: 0px;
    }
    .dealer-min-650-m {
      height: calc(100vh - 330px) !important;
    }
  }
}
</style>
